@import '~react-image-gallery/styles/scss/image-gallery.scss';

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

body {
  background-color: #fafafa;
}

.image-gallery {
  width: 100%;
  height: auto;

  .image-gallery-content {
    .image-gallery-slide {
      .image-gallery-image {
        width: 100%;
        height: 600px;
        max-height: 600px;
        overflow: hidden;
        object-position: center center;
      }
    }

    &.fullscreen .image-gallery-slide .image-gallery-image {
      height: auto;
      max-height: 100vh;
    }
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
